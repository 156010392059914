import Vue from 'vue'
import Router from 'vue-router'
import Store from './store/index.js'

Vue.use(Router);

let router = new Router({
  mode: 'history',
  base: '/',

  routes: [

    {
      path: "",
      name: "root",
      // Routing decisions for this path are handled in beforeEach
    },

    {
      path: '/identify',
      name: 'start-identification',
      component: () => ({
        component: import('./views/pages/StartIdentification.vue'),
        // Delay before showing the loading component. Default: 200ms.
        delay: 200,
        // The error component will be displayed if a timeout is
        // provided and exceeded. Default: Infinity.
        timeout: 3000
      })
    },

    {
      path: '/identification-callback',
      name: 'finish-identification',
      component: () => ({
        component: import('./views/pages/FinishIdentification.vue'),
        // Delay before showing the loading component. Default: 200ms.
        delay: 200,
        // The error component will be displayed if a timeout is
        // provided and exceeded. Default: Infinity.
        timeout: 3000
      })
    },

    {
      path: '/info',
      name: 'info',
      component: () => ({
        component: import('./views/pages/About.vue'),
        // Delay before showing the loading component. Default: 200ms.
        delay: 200,
        // The error component will be displayed if a timeout is
        // provided and exceeded. Default: Infinity.
        timeout: 3000
      })
    },


    {
      path: '/404',
      name: '404',
      component: () => import('./views/pages/NotFound.vue'),
    },
    {
      name:'403',
      path: '/403',
      component: () => import('./views/pages/Forbidden.vue'),
    },
    {
      path: '*',
      redirect: {name:'404'}
    }, // if no match, go back to 404

  ]
});

router.beforeEach(async (to, from, next) => {

  // Giving an optional parameter of "uiBusy" as "true"
  // e.g. <router-link :to="{ name:'xxxx', params:{uiBusy: true} }">
  // will add a busy item called "loadPage".
  //
  // This can be utilized to show a loading spinner
  // in the page of your choice using:
  // e.g. if (this.$store.getters['ui/isBusy'] == true)
  //
  // And please remove the busy item after that using:
  // this.$store.commit("ui/REMOVE_BUSY_ITEM","loadPage");
  if(to.params && to.params.uiBusy == true)
    Store.commit("ui/ADD_BUSY_ITEM","loadPage");

  // Root always redirects to the service info page
  if(to.name == 'root') {
    return next({
      name: 'info',
      props: route => Object.assign({}, route.query, route.params)
    });
  }

  next();

});

// Components may add e.g. afterEach handlers to the exported router

export default router;
