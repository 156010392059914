<template>
  <div id="app">

    <div class="top-bar">
      <div class="nav-bar">
        <img src="./assets/img/documill-logo-32x32.svg" style="width: 40px;" alt="Documill logo" uk-img>
        <span class="service-title">{{ $t("identification.service_name")}}</span>
        <LocaleSelector class="locale-selector" id="user-settings" :selectedLocale="getUserLocale()" @updated="updateLocale"/>
      </div>
    </div>

    <div id="documill-main-section" uk-height-viewport="expand: true">
      <!-- This is where all the page components are shown -->
      <router-view/>
    </div>

    <div class="footer">
      <div class="nav-bar">
        <br>
        <span class="copyright">© {{currentYear}} Documill</span>
      </div>
    </div>

    <!-- Version poller component for checking UI version. See DOS-1691. -->
    <VersionPoller />
  </div>
</template>

<script>
/**
 * The root Vue application of Identification Service.
 *
 * @author Documill
 */

import $ from 'jquery';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import VersionPoller from './views/components/VersionPoller';
import LocaleSelector from './views/components/LocaleSelector';

// Import minified or non-minified UIKit CSS depending on whether we're in production or development mode
require(UIKIT_CSS);

UIkit.use(Icons);

//Make below imports global
window.UIkit = UIkit; // UIKit
window.$ = $; // JQuery

document.title="Documill Identification";

export default {
  name: 'App',

  components: {
    VersionPoller,
    LocaleSelector
  },

  data: function () {
    return {
    }
  },

  /**
   * Local variables that needs to be computed first
   */
  computed: {
    isUIBusy: function() {
      return this.$store.getters['ui/isBusy'];
    },

    currentYear: function(){
      return new Date().getFullYear();
    },

    uiLocale: function() {
      return this.$store.getters['ui/getUiLocale'];
    },
  },

  methods: {
      getUserLocale: function() {
        return this.$i18n.locale;
      },

      updateLocale: function(locale) {
        this.$store.dispatch("ui/updateUiLocale", locale.key).then();
        sessionStorage.setItem("locale",locale.key);
      },
  },

  watch: {

    uiLocale(locale) {
      if(locale) {
        this.$i18n.locale = locale; // Change locale. See DOS-1892.
        // Update locale for Flatpickr. See DOS-1951.
        // The locale is required in the format 'xx' (e.g. 'fi', 'de').
        this.$flatpickr.localize(this.$flatpickrLanguages[locale]);
      }
    }
  },

  mounted: function() {
    // When mounting the App set the Vuex state User Locale as i18n locale to actually enable
    // the locale. This is needed because $i18n loses the locale information when e.g. a page
    // is refreshed.

    if(this.uiLocale) {
      this.$i18n.locale = this.uiLocale;
      // DOS-1951: Set locale for flatpickr.
      this.$flatpickr.localize(this.$flatpickrLanguages[this.uiLocale]);
    }

    let localeSession = sessionStorage.getItem("locale");
    if(localeSession)
    {
      this.$store.dispatch("ui/updateUiLocale", localeSession).then();
    }
  }
}

</script>

<style>
@import "./documill.css";

@media only screen and (max-width: 900px) {
  .uk-modal-footer .uk-button{
    margin-right: 15px;
    margin-left: 0px;
    margin-bottom: 15px;
  }

  .service-title{
    font-size: 20px !important;
  }

  .nav-bar{
    width: 100% !important;
  }

  .locale-selector{
    margin-top: 15px;
    margin-right: 15px;
    float: right;
    bottom: 5px;
  }

  .copyright{
    margin-left: 15px;
  }
}

.top-bar{
  background: #FFFFFF;
  width: 100%;
  height: 100px;
  border-bottom-style: solid;
  border-bottom-width: thin;
  border-bottom-color: #ccc !important;
}

.footer{
  background: #FFFFFF;
  width: 100%;
  height: 70px;
  border-top-style: solid;
  border-top-width: thin;
  border-top-color: #ccc !important;
}

.nav-bar{
  white-space: nowrap;
  width: 50%;
  margin: 0 auto;
}

.service-title{
  font-family: "Titillium Web", sans-serif !important;
  font-size: 35px;
  position: relative;
  top: 5px;
  left: 10px;
}

.copyright{
  font-family: "Titillium Web", sans-serif !important;
  font-size: 17px;
}

.locale-selector{
    margin-top: 15px;
    float: right;
    bottom: 5px;
}
</style>
