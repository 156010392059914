<template>
  <td class="td-router-link">
    <!--
      Note: The actual cell content also needs to be wrapped inside a router-link because click
            events will not pass through the content to the hyperlink-layer below.
    -->
    <router-link tag="a" draggable="false" class="router-link-content" :to="to">
      <slot /> <!-- Actual table cell content. -->
    </router-link>

    <!-- Hyperlink layer beneath content which will stretch to fill the whole cell. -->
    <router-link tag="a" draggable="false" class="router-link-hyperlink-layer" :to="to" />
  </td>
</template>

<script>

/**
 * Table cell router-link component.
 *
 * This is a convenience component that allows easily making a whole table cell a clickable
 * hyperlink component. This is done by creating a transparent and absolutely positioned
 * 100% width/height <a> block inside the table cell which will be used as a router link. This will
 * effectively make the whole table cell clickable.
 *
 * For more information see DOS-1801.
 *
 * How to use:
 * <td-router-link :to>
 *   [table cell content]
 * </td-router-link>
 *
 * Note: The component has been globally imported in main.js so it does not need to be imported
 *       again.
 *
 * @author Documill 2021
 */
export default {
  name: "td-router-link",

  props: {
    /** Router link "to" property. */
    to: {
      type: Object,
      required: true
    },
  },
};
</script>

<style scoped>

td.td-router-link {
  /** Needs to be relative so that the content can be absolutely positioned. */
  position: relative;
}

td.td-router-link > a.router-link-hyperlink-layer {
  height: -webkit-calc(100vh);
  height: -moz-calc(100vh);
  height: calc(100vh);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  display: block;
  position: absolute;
  z-index: 0; /* Below the actual table cell content. */

  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  -webkit-user-drag: none;
}

a.router-link-content,
a.router-link-content:hover,
a.router-link-content:visited,
a.router-link-content:link,
a.router-link-content:active {
  color: inherit;
  text-decoration: none; /* Do not add hyperlink decorations. */
  position: relative;

  z-index: 1; /* Above the hyperlink layer. */
}

</style>
