<template>
  <div class="unselectable-text">
    <button
      :disabled="disabled"
      class="uk-button border dropdown"
      :class="{'uk-disabled': disabled}"
      type="button"
      :style="{'width': dropdownWidth}"
    >
      <span v-if="showTooltip && !isOpen" :uk-tooltip="`title:${selectedOptionLabel}; delay: 600; pos: bottom-left`"
        class="string_span option-label">{{selectedOptionLabel}}</span>
      <span v-else class="string_span option-label">{{selectedOptionLabel}}</span>
      <span uk-icon="icon: chevron-down" style="flex-shrink:0"></span>
    </button>
    <div
      v-bind:id="dropdownId"
      class="documill-dropdown-menu"
      uk-dropdown="mode: click; delay-hide:0;"
      :style="{'width': dropdownWidth, 'position': 'fixed', 'z-index': 9999999}"
    >
      <div v-if="searchBar == true" class="search-input">
        <form class="uk-search uk-search-default">
          <span uk-search-icon></span>
          <input
            class="uk-search-input"
            type="search"
            :placeholder="searchBarPlaceholder"
            v-model="searchValue"
            onkeypress="return event.keyCode != 13"
          />
        </form>
      </div>
      <simplebar :key="searchValue"
        :style="{'max-height': dropDownMaxHeight}"
        style="background-color: #fff;border-radius: 4px;">
        <ul class="uk-list">
          <!--
            To prevent "wobbling" effect when hovering over a long option, can be assigned a
            title with the same value (see DOS-861).
          -->
          <li class = "option-label"
            v-for="option in filteredOptions"
            v-bind:class="{active: option.isSelected,
          'uk-disabled': option.isDisabled,
          'separated-option': option.isSeparated}"
            v-html="$options.filters.highlight(option.label, searchValue)"
            v-bind:key="option.key"
            v-on:click="updateSelected(option)"
            :uk-tooltip="option.isTooltip ? `title:${option.tooltipMessage}; pos: bottom; delay: 600` : ''"
          >{{option.label}}</li>
        </ul>
      </simplebar>
    </div>
  </div>
</template>


<script>
/*
 * Dropdown component
 *
 * How to use:
 * <Dropdown
 * dropdownId="idOfTheDropdown"
 * :options="arrayOfObjects"
 * placeholder="pleaseSelectAnOption"
 * v-on:updateOption="yourMethodWhenDropdownItemIsClicked"
 * :search-bar="true"
 * dropdownWidth="195px">
 * </Dropdown>
 *
 * "dropdownId": html ID of the component when it is rendered.
 *
 * ":options" : options that are included in the Dropdown.
 * Should at least contain 'key', 'label' and 'isSelected':
 * arrayOfObjects: [
 *   {key: 123, label: 'Zero', isSelected: true},
 *   {key: 231, label: 'One', isSelected: false},
 *   {key: 312, label: 'Two', isSelected: false},
 * ],
 *
 * Additionally, the label of the button can be customized by
 * providing buttonLabel data inside the arrayOfObjects:
 * arrayOfObjects: [
 *   {label: 'Zero', buttonLabel,: 'selected: Zero', isSelected: true},
 *   {label: 'One', buttonLabel,: 'selected: One', isSelected: false},
 *   {label: 'Two', buttonLabel,: 'selected: Two', isSelected: false},
 * ],
 *
 * Each option can also be disabled by providing isDisabled data
 * inside the arrayOfObjects:
 * arrayOfObjects: [
 *   {label: 'Zero', buttonLabel,: 'selected: Zero', isDisabled: true},
 *   {label: 'One', buttonLabel,: 'selected: One', isDisabled: false},
 *   {label: 'Two', buttonLabel,: 'selected: Two', isDisabled: false},
 * ],
 *
 * Each option can also be hidden by providing "isVisible=false" data
 * inside the arrayOfObjects. Do note that the isVisible defaults to 'true'.
 * arrayOfObjects: [
 *   {label: 'Zero', buttonLabel,: 'selected: Zero', isVisible: true},
 *   {label: 'One', buttonLabel,: 'selected: One', isVisible: false},
 *   {label: 'Two', buttonLabel,: 'selected: Two', isVisible: false},
 * ],
 *
 * "placeholder": String that will be displayed when nothing is selected.
 *
 * "v-on:updateOption": Put the name of the method that should be executed
 * when user select an option.
 * The method can receive 2 parameters:
 * - the selected option
 * - the previous option
 *
 * "searchBar": Boolean to enable search option inside dropdown.
 * Default is 'false'.
 *
 * "dropdownWidth": String to override the width of the dropdown.
 *
 * @author Documill
 */
export default {
  name: "Dropdown",

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    options: Array,
    dropdownId: String,
    placeholder: {
      type: String,
      required: false,
      default: function() {
        return this.$t("common.select_option");
      }
    },
    searchBar: {
      type: Boolean,
      default: false
    },
    searchBarPlaceholder: {
      type: String,
      required: false,
      default: function() {
        return this.$t("common.search");
      }
    },
    dropdownWidth: {
      type: String,
      default: "160px"
    },

    dropDownMaxHeight: {
      type:String,
      default:'none'
    }

  },
  data: function() {
    return {
      searchValue: "",
      showTooltip: false,
      isOpen: false,
    };
  },

  computed: {
    /*
     * The selected option in the dropdown menu.
     */
    selectedOption: function() {
      return this.options.find(option => option.isSelected == true);
    },

    /*
     * Text to show in the dropdown button.
     * Either the selected option or the placeholder text (if none are selected).
     */
    selectedOptionLabel: function() {
      if (this.selectedOption) {
        let showTooltipLocal = this.showTooltip;
        this.$nextTick(() => {
          $('.string_span').each(function() {
            if ($(this)[0].scrollWidth - $(this)[0].clientWidth > 0) {
              showTooltipLocal = true;
            }
          });
          // FIXME: Do not cause side-effects (i.e. update data-variables) in computed functions.
          this.showTooltip = showTooltipLocal;
        });
        if (this.selectedOption.buttonLabel) {
          return this.selectedOption.buttonLabel;
        }
        return this.selectedOption.label;
      } else {
        return this.placeholder;
      }
    },

    /**
     * Options filtered by search value for UI usage.
     */
    filteredOptions: function() {
      let searchValue = this.searchValue;
      return this.options.filter(option => {
        if(option.isVisible === false)
          return false; // Not visible.

        if(searchValue == null || searchValue === "")
          return true;

        return option.label.toLowerCase().includes(searchValue.toLowerCase());
      });
    }
  },

  methods: {
    /*
     * Function to execute when user select an option.
     *
     * This function clears the 'searchValue', closes the dropdown menu and
     * sets the 'isSelected' value of the selected option as 'true'.
     *
     * The function will also emit an event called 'updateOption' to the parent
     * so the parent can perform process it further.
     *
     * Note: To update the selected option, this component relies
     *       on its Parent. Therefore, don't forget to update the option list
     *       when processing 'updateOption' event inside its Parent.
     */
    updateSelected(selectedOption) {
      this.searchValue = ""; // Clear the search string.

      UIkit.dropdown("#" + this.dropdownId).hide();
      let previousOption = this.options.find(
        option => option.isSelected == true
      );
      this.$emit("updateOption", selectedOption, previousOption);
    },

    /*
     * Function to select dropdown option.
     * Is executed when user press a key on a keyboard.
     *
     * If the dropdown list is opened, the function reacts to up/down arrow keys
     * to update the selected option.
     *
     * While selection in process, the function disables the parent page scrolling.
     * When dropdown is closed or selection is updated, scrolling should be enabled.
     *
     * The function will also emit an event called 'updateOption' to the parent
     * so the parent can perform process it further.
     *
     * Note: To update the selected option, this component relies
     *       on its Parent. Therefore, don't forget to update the option list
     *       when processing 'updateOption' event inside its Parent.
     */
    selectByKeyArrow () {

      if(this.isOpen) {

        let previousOption = this.options.find(
                option => option.isSelected == true
        );
        let previousOptionIndex = this.options.indexOf(previousOption);
        let nextOptionIndex = previousOptionIndex;

        // Handle selection of filtered options by search value
        if(this.searchValue != "") {
          if(this.filteredOptions.length == 0)
            return;

          if(!this.filteredOptions.some(option => option.key == this.selectedOption.key)) {
            nextOptionIndex = this.options.findIndex(option => option.key == this.filteredOptions[0].key);
          }
        }

        if(event.keyCode == 38 && previousOptionIndex > 0) {
          nextOptionIndex -= 1;

          while (this.options[nextOptionIndex].isDisabled) {
            nextOptionIndex -= 1;
            if(nextOptionIndex === -1)
              return;
          }

          this.$emit("updateOption", this.options[nextOptionIndex], previousOption);
        }
        else if(event.keyCode == 40 && previousOptionIndex < this.options.length - 1) {
          nextOptionIndex += 1;

          while(this.options[nextOptionIndex].isDisabled ) {
            nextOptionIndex += 1;
            if(nextOptionIndex === this.options.length)
              return;
          }
          this.$emit("updateOption", this.options[nextOptionIndex], previousOption);
        }
      }
    },

    /*
     * Disables page scrolling.
     */
    disableScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      window.onscroll = function() {
        window.scrollTo(scrollLeft, scrollTop);
      };
    },

    /*
     * Enables page scrolling.
     */
    enableScroll() {
      window.onscroll = function() {};
    },

    /**
     *
     * Validates that each dropdown option has a key
     * and key is unique.
     *
     * see DOS-1847.
     */
    isValidOptionKeys() {
      // Checks that all options have a key.
      this.options.forEach(option => {
        if(!option.hasOwnProperty('key'))
          return false;
      })

      const uniqueKeys = [...new Set(this.options.map(option => option.key))];

      return this.options.length == uniqueKeys.length
    }
  },

  created: function() {
    if(!this.isValidOptionKeys())
      this.$log.error("Dropdown option 'key' parameter is missing or some keys are not unique.");
  },

  mounted: function() {
    // Call "event.stopPropagation" so we do not bubble the generic show/shown/hide/etc events
    // outside the scope of this Vue component. This is because these events are used also in other
    // components and bubbling of these events may trigger those parent event listeners
    // unnecessarily.
    // Note: See issue DOS-1787.
    // Note: If you wish to propagate the events to parent component wrap them inside custom
    //       events that are easily distinguished (e.g. "dropdown-menu-closed").

    UIkit.util.on("#" + this.dropdownId, "shown", (event) => {
      event.stopPropagation();
    });
    UIkit.util.on("#" + this.dropdownId, "hidden", (event) => {
      event.stopPropagation();
    });

    UIkit.util.on("#" + this.dropdownId, "show", (event) => {
      this.disableScroll();
      this.isOpen = true;

      event.stopPropagation();
    });

    UIkit.util.on("#" + this.dropdownId, "hide", (event) => {
      this.enableScroll();
      this.isOpen = false;

      event.stopPropagation();
    });

    document.addEventListener("keyup", this.selectByKeyArrow);
  },

  destroyed() {
    document.removeEventListener("keyup", this.selectByKeyArrow);
  }
};
</script>

<style scoped>

.option-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.search-input {
  background-color: #fff;
  border-radius: 4px;
  cursor: default;
  font-size: 0.875rem;
  padding: 5px 15px;
  margin: 0;
}

.separated-option {
  border-top:1px solid #DCDCDC;
  border-bottom:1px solid #DCDCDC;
}

</style>
