import Vue from 'vue'
import Vuex from 'vuex'
import ui from './modules/ui.js'


Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
      ui
    },

    state:{

    },

    getters: {

    },

    mutations: {

    },

    actions: {

    },
})
