/**
 * The root JS application of Flow Service.
 *
 * @author Documill
 */

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import LoadScript from 'vue-plugin-load-script';
import apiCall from './utils/api';
import SmartTable from 'vuejs-smart-table';
import VueKonva from 'vue-konva';
import VueWait from 'vue-wait';
import VueLogger from 'vuejs-logger';
import VueChangeCase from 'vue-change-case';

import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';
import {StyleFactory} from '@hscmap/vue-window'

import TableCellRouterLink from './components/TableCellRouterLink';
import i18n from './i18n';
import flatpickr from "flatpickr";
import flatpickrLanguages from "flatpickr/dist/l10n/";
import IdleVue from 'idle-vue';
import FeatureFlipping from 'vue-feature-flipping';
import { setEnabledFeatures } from 'vue-feature-flipping';

/* vuejs logger related constants */
const isProduction = process.env.NODE_ENV === 'production';
const vuejsLoggerOptions = {
  isEnabled: true,
  logLevel: 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true
};

/* DOS-1401
   If user is authenticated, but unauthorized to use Leap API,
   e.g. due to the token expiration, logout and redirect to org picker */
apiCall.interceptors.response.use(
    function (response) {
      return response;
    },
    (error) => {
      if(error.response
         && (store.getters.isAuthenticated && error.response.status === 401 || 403)
         && error.response.data.failureType == 'TOKEN_EXPIRED') {
        store.dispatch("authLogout");
        location.reload();
      }
      return Promise.reject(error);
    }
);

Vue.config.productionTip = false;
Vue.use(require('vue-moment'));
Vue.use(SmartTable);
Vue.use(VueKonva);
Vue.use(VueWait);
Vue.use(LoadScript);
Vue.use(VueLogger, vuejsLoggerOptions);
Vue.use(VueChangeCase);
Vue.prototype.$apiCall = apiCall;
Vue.prototype.$flatpickr = flatpickr;
Vue.prototype.$flatpickrLanguages = flatpickrLanguages;
Vue.component('simplebar', simplebar);
Vue.use(require('@hscmap/vue-window'));
Vue.component('hsc-window-style-blank', StyleFactory( {

} ) );

Vue.use(IdleVue, {
  eventEmitter: new Vue(),
  idleTime: window.IdentificationWebsiteGlobalConfig.ui.idleTime
});

Vue.component('td-router-link',TableCellRouterLink);

Vue.filter('highlight', function(text, searchFilter){
  return text.replace(
    new RegExp(searchFilter, "gi"),
    match => '<span class="highlighted-search">' + match + "</span>"
  );
});

// DOS-1880: Override Global settings for flatpickr.
flatpickr.setDefaults({
  disableMobile: true,
});

let features = [];
if(!isProduction)
  features.push("overlay-annotations");

setEnabledFeatures(features);
Vue.use(FeatureFlipping);

function loadApp() {
  new Vue({
    router,
    render: h => h(App),
    store,
    i18n,
    wait: new VueWait()
  }).$mount('#app')
}

/* :) */
/* ASCII from https://www.text-image.com/convert/ascii.html */

let documillLogo = '\n'+
'        `+dddd/ :yddhs/`\n'+
'       :ssyddd++ssshdddd+`\n'+
'          :ddd/     .odddy`\n'+
'          :ddd/       :ddds\n'+
'          :ddd/       `hddd\n'+
'```````   :ddd/       -ddds\n'+
'`/oooo+   :ddd/     `+dddh.\n'+
'  `:oo+   :dddyooosydddds`\n'+
'    `:+   :ddddddddddy+.\n'+
'      `   `--------.`\n';

console.log(documillLogo);
console.log("Documill Leap");

store.dispatch("ui/readVersion").then(() => {
  let version = store.getters['ui/getVersion'];
  console.log("Front-end version: " + version);
});

loadApp();
