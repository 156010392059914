/**
 * Vue Store related to UI state information.
 *
 * Do note that this module is namespaced so refer to it's actions and
 * mutations with 'ui/' prefix.
 *
 * @author Documill 2021
 */

import axios from 'axios';
import Vue from 'vue';

export default {

  namespaced: true,

  state: {
    ui: {
      /**
       * Currently running file uploads. Keys are file names and values are
       * numbers representing percentual progress between 0-100.
       */
      fileUploads: {},

      // TODO: Consider creating separation for different UI parts, e.g.
      //       "main-area", "header", "footer", "toolbar" etc.

      /** Items that make whole UI "busy" (or to being in "loading" state). */
      itemsBusy: {},

      /** Whether there is newer UI version available. */
      newVersionAvailable: false,

      /** Task identities that should have loading indicator. */
      tasksCompleting: {},

      /** Current UI version. */
      version: null,

      /** UI locale. */
      locale: 'en'
    }
  },

  getters: {

    /**
     * Gets the current UI version.
     *
     * How to use:
     * this.$store.getters['ui/getVersion']
     *
     * @returns {String} version (may be null, if version is not yet read)
     */

    getVersion: (state) => {
      return state.ui.version;
    },

    /**
     * Tests if there are any UI items that have caused the whole UI become
     * "busy".
     *
     * How to use:
     * this.$store.getters['ui/isBusy']
     *
     * @returns {Boolean} whether the UI is loading ("busy")
     */

    isBusy: (state) => {
      return Object.keys(state.ui.itemsBusy).length > 0;
    },

    /**
     * Tests if there is a specific UI items that have caused the whole UI become "busy".
     *
     * How to use:
     * this.$store.getters['ui/isItemBusy'](itemName)
     *
     * @param item item name
     *
     * @returns {Boolean} whether the UI is loading ("busy")
     */

    isItemBusy: (state) => itemName => {
      return state.ui.itemsBusy[itemName] != null;
    },

    /**
     * Gets currently selected ui locale.
     *
     * How to use:
     * this.$store.getters.getUiLocale
     */

    getUiLocale:  (state) => {
      return state.ui.locale;
    }
  },

  mutations: {

    /**
     * Lets the state know that the following UI item has made the whole
     * UI "busy".
     *
     * How to use:
     * this.$store.commit("ui/ADD_BUSY_ITEM","item");
     *
     * @param state state
     * @param {*} item item that caused the UI to become "busy"
     */

    ADD_BUSY_ITEM(state, item) {
      Vue.set(state.ui.itemsBusy, item, true);
    },

    /**
     * Lets the state know that the following UI item is no longer making the
     * whole UI "busy".
     *
     * How to use:
     * this.$store.commit("ui/REMOVE_BUSY_ITEM","item");
     *
     * @param state state
     * @param {*} item item that caused the UI to become "busy"
     */

    REMOVE_BUSY_ITEM(state, item) {
      Vue.delete(state.ui.itemsBusy, item);
    },

    /**
     * Marks that there is a new UI version available.
     *
     * How to use:
     * this.$store.commit("ui/SET_NEW_VERSION_AVAILABLE");
     *
     * @param state
     */

    SET_NEW_VERSION_AVAILABLE(state) {
      Vue.set(state.ui,"newVersionAvailable",true);
    },

    /**
     * Sets the current UI version.
     *
     * How to use:
     * this.$store.commit("ui/SET_VERSION","version");
     *
     * @param state
     * @param {String} version version number
     */

    SET_VERSION(state,version) {
      Vue.set(state.ui,"version",version);
    },

    /**
     * Sets the current UI locale.
     *
     * How to use:
     * this.$store.commit("ui/SET_UI_LOCALE","locale");
     *
     * @param state
     * @param {String} locale
     */

    SET_UI_LOCALE(state, locale) {
      state.ui.locale = locale;
    }
  },

  actions: {
    // Note: No need for actions as "isBusy" state changes are preferred to be
    //       synchronous instead of asynchronous (actions).
    // TODO: They could still be useful in some situations.

    /**
     * Reads the UI version information from server.
     *
     * How to use:
     * this.$store.dispatch("ui/readVersion");
     */
    async readVersion(context) {
      // Simply add timestamp to request to bust browser cache.
      // Note: We can't use "api.js" as it sets the basePath to target the identification-controller-api.
      //       Because of that the "raw" axios is used.
      // TODO: Could probably also try setting no-cache headers.
      // TODO: We could also read other build related information.

      let url = "/build.json?" + new Date().getTime();
      try {
        const result = await axios.get(url);
        let env = result.data;

        context.commit("SET_VERSION",env.version);
        return result;
      }
      catch(error) {
        this._vm.$log.error("Failed to query UI version information.",error);
        throw error;
      }
    },

    /**
     * Updates the ui locale.
     *
     * How to use:
     * this.$store.dispatch("ui/updateUiLocale", locale);
     *
     * @param {String} locale as ISO-639-1 two letter code
     */
    async updateUiLocale(context, locale) {
      context.commit("SET_UI_LOCALE",locale);
    }
  }
}
