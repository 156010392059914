<template>
  <Dropdown
    :key="'locale-' + id"
    :dropdownId="'locale-' + id"
    :options="localeOptions"
    placeholder="Select a locale"
    @updateOption="updateLocale" />
</template>

<script>

/**
 * Locale selector dropdown.
 *
 * Emits 'updated" event when the selected option changes.
 *
 * @author Documill 2021
 */

import Dropdown from './Dropdown';

export default {
  name: "LocaleSelector",

  components: {
    Dropdown
  },

  props: {
    id: {
      type: String,
      required: true
    },

    selectedLocale:{
      type: String,
      required: true
    }
  },

  data: function() {
    return {
      /** Mapping from locale codes to locale names. */
      locale_names: {
        'en': 'English',
        'fi': 'Suomi',
        'nl': 'Dutch',
        'de': 'Deutsch'
      }
    }
  },

  computed: {

    localeOptions: function() {
      let locales = this.$i18n.availableLocales;
      let localeOptions = [];

      for(let i=0; i<locales.length; i++)
      {
        let locale = locales[i];
        let localeName = this.locale_names[locale];

        localeOptions.push({
          key: locale,
          label: localeName,
          buttonLabel: localeName,
          isSelected: locale === this.selectedLocale
        });
      }

      return localeOptions;
    }
  },

  methods: {

    updateLocale: function(locale){
      this.$emit('updated', locale);
    }

  },
};
</script>

<style scoped>

  table {
    border-collapse: collapse;
    width: 100%;
  }

  tr {
    border-bottom: 1px solid #e5e5e5;
  }

  tr:last-child {
    border-bottom: none;
  }

  td, th {
    text-align: left;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 20px;
  }

  td:first-child {
    width:150px;
    padding-right: 30px;
  }

</style>
